import React, { useEffect, useState, useMemo } from "react";
import {
  Row,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  TreeSelect,
} from "antd";
import {
  CloseOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SlidersOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { ROLE_CATALOG_READ_ONLY_USER } from "../../../constants/roles";
import { Link } from "react-router-dom";
import { _get } from "../../../utils/lodashUtils";
import ModalCom from "../../common/Modal";
import { PRIMARY_COLOR } from "../../../constants/constants";

function CatalogTableTitle(props) {
  const {
    toggleFilters,
    setToggleFilters,
    roleCode,
    tablePaginationParams,
    loadCatalogsData,
    setTablePaginationParams,
    activeFilters,
    setActiveFilters,
    manufacturerFilterListData,
    categoryFilterData,
    syncConnectorFilterData,
    loadCatalogModal,
    setLoadCatalogModal,
  } = props;

  const renderTreeNodes = (nodes) =>
    nodes.map((item) => {
      if (item.items) {
        return (
          <TreeSelect.TreeNode
            title={item.name}
            value={item.id}
            dataRef={item}
            key={item.id}
          >
            {renderTreeNodes(item.items)}
          </TreeSelect.TreeNode>
        );
      }
      return (
        <TreeSelect.TreeNode
          value={item.id}
          title={item.name}
          {...item}
          key={item.id}
        />
      );
    });

  return (
    <Row justify={"end"} style={{ backgroundColor: "#f4f4f4" }}>
      <Col>
        <Button size={"large"} onClick={() => setToggleFilters(!toggleFilters)}>
          {toggleFilters ? <CloseOutlined /> : <SlidersOutlined />}
        </Button>

        {!(roleCode === ROLE_CATALOG_READ_ONLY_USER) ? (
          <Link to={"/catalog/add"}>
            <Button size={"large"} type={"primary"} icon={<PlusOutlined />}>
              Add Item
            </Button>
          </Link>
        ) : null}

        <Button
          size={"large"}
          type={"secondary"}
          icon={<SyncOutlined />}
          onClick={() => {
            if (tablePaginationParams.loadAllowed) loadCatalogsData();
            else {
              // setLoadCatalogModal(true);
              setTablePaginationParams({
                ...tablePaginationParams,
                loadAllowed: true,
                filters: { ...activeFilters },
              });
            }
          }}
        >
          Load
        </Button>
      </Col>
      {toggleFilters && (
        <Col span={24}>
          <Form
            className={"filter-form"}
            onFinish={() =>
              setTablePaginationParams({
                ...tablePaginationParams,
                loadAllowed: true,
                offset: 0,
                filters: { ...activeFilters },
              })
            }
          >
            <Space className={"mt-10 mb-10 ml-10 mr-10"}>
              <InputNumber
                value={activeFilters.id}
                placeholder="ID"
                onChange={(value) =>
                  setActiveFilters((prev) => {
                    return { ...prev, id: value ? value : undefined };
                  })
                }
              />
              <Input
                value={activeFilters.spn}
                placeholder="SPN"
                onChange={(e) =>
                  setActiveFilters((prev) => {
                    return { ...prev, spn: e.target.value };
                  })
                }
              />
              <Input
                value={activeFilters.description}
                placeholder="Description"
                onChange={(e) =>
                  setActiveFilters((prev) => {
                    return { ...prev, description: e.target.value };
                  })
                }
              />
              <Select
                optionFilterProp="children"
                showSearch
                value={activeFilters.manufacturer_id}
                placeholder={"Manufacturer"}
                style={{ width: 200 }}
                allowClear
                onChange={(value) =>
                  setActiveFilters((prev) => {
                    return { ...prev, manufacturer_id: value };
                  })
                }
              >
                {_get(manufacturerFilterListData, "data", []).map((item) => (
                  <Select.Option value={item.id} key={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
              <TreeSelect
                placeholder={"Category"}
                allowClear
                value={
                  activeFilters.category_id
                    ? activeFilters.category_id.toString()
                    : null
                }
                style={{ width: 200 }}
                onChange={(value) =>
                  setActiveFilters((prev) => {
                    return { ...prev, category_id: value ? value : undefined };
                  })
                }
              >
                {renderTreeNodes(categoryFilterData)}
              </TreeSelect>
              <Select
                optionFilterProp="children"
                showSearch
                value={activeFilters.checked_sc}
                placeholder={"Sync Connector"}
                style={{ width: 200 }}
                allowClear
                mode={"multiple"}
                onChange={(value) =>
                  setActiveFilters((prev) => {
                    return {
                      ...prev,
                      checked_sc: value.length ? value : undefined,
                    };
                  })
                }
              >
                {_get(syncConnectorFilterData, "data", []).map((item) => (
                  <Select.Option value={item.id} key={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
              <Select
                value={activeFilters["is_active"]}
                placeholder={"Status"}
                style={{ width: 200 }}
                allowClear
                onChange={(value) =>
                  setActiveFilters((prev) => {
                    return {
                      ...prev,
                      is_active: value,
                    };
                  })
                }
              >
                <Select.Option value={true}>Active</Select.Option>
                <Select.Option value={false}>Inactive</Select.Option>
                <Select.Option value={undefined}>All</Select.Option>
              </Select>
              <Form.Item>
                <Button type={"primary"} htmlType={"submit"}>
                  Apply
                </Button>
              </Form.Item>
              <Button
                type={"secondary"}
                onClick={() => {
                  setActiveFilters({});
                  setTablePaginationParams({
                    ...tablePaginationParams,
                    filters: {},
                  });
                }}
              >
                Clear
              </Button>
            </Space>
          </Form>
        </Col>
      )}
      {/* <ModalCom
        width='500px'
        closeIcon={null}
        open={loadCatalogModal}
        title={
          <Row>
            <Col>
              <ExclamationCircleOutlined
                style={{
                  color: PRIMARY_COLOR,
                  fontSize: '22px',
                  marginRight: '16px',
                }}
              />
            </Col>
            <Col>
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  marginBottom: '12px',
                }}
              >
                Are you sure you want to load entire master?
              </div>
              <span style={{ fontWeight: 400 }}>
                This could take a few minutes.
              </span>
              <br />
              <br />
              <br />
            </Col>
          </Row>
        }
        onOk={() => {
          setLoadCatalogModal(false);
          setTablePaginationParams({
            ...tablePaginationParams,
            loadAllowed: true,
            filters: { ...activeFilters },
          });
        }}
        onCancel={() => {
          setLoadCatalogModal(false);
        }}
      ></ModalCom> */}
    </Row>
  );
}

export default CatalogTableTitle;
