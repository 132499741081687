import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import React from 'react';
import {
  DISTRIBUTOR,
  LOGS,
  MANUFACTURER,
  MANUFACTURER_MAPPED_STATE,
} from '../../constants/constants';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getSyncConnectorIntegrationsData } from '../../redux/Slices/systemSyncConnectorIntegrationSlice';
import { lowerCase } from 'lodash';
import { prohibitDecimalEntry } from '../../utils/common';
import {
  SyncOutlined,
  CheckCircleFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons';

const marginSpace = {
  marginLeft: '24px',
};

export const IntegrationFilters = ({ tableSource, id }) => {
  const [integrationFiltersForm] = Form.useForm();

  const dispatch = useDispatch();

  const logsData = useSelector(
    (state) => state.systemSyncConnectorIntegrationReducer.logsData
  );

  const manufacturerAddedFilters = () => {
    return (
      <Form.Item name={'manufaturerMappedState'}>
        <Select
          placeholder='State'
          options={Object.values(MANUFACTURER_MAPPED_STATE).map((item) => ({
            value: item,
            label: item,
          }))}
          style={{ width: '150px', ...marginSpace }}
        />
      </Form.Item>
    );
  };

  const logsFilters = () => {
    return (
      <Row>
        <Col>
          <Form.Item name={'status'}>
            <Select
              placeholder='Status'
              style={{ width: '150px' }}
              options={[
                {
                  value: 'all',
                  label: 'All',
                },
                {
                  value: 'success',
                  label: (
                    <>
                      <CheckCircleFilled style={{ color: 'green' }} />
                      &nbsp;Success
                    </>
                  ),
                },
                {
                  value: 'failure',
                  label: (
                    <>
                      <ExclamationCircleFilled style={{ color: 'red' }} />
                      &nbsp;Failure
                    </>
                  ),
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name={'item_id'}>
            <Input
              className='remove-side-arrows'
              placeholder='Item Id'
              type='number'
              onKeyDown={(evt) => prohibitDecimalEntry(evt)}
              style={{ width: '150px', ...marginSpace }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name={'connector_part_number'}>
            <Input
              placeholder='CPN'
              style={{ width: '150px', ...marginSpace }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name={'create_date'}>
            <DatePicker
              style={{ width: 200, ...marginSpace }}
              placeholder='Date'
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const updateData = (filteredData) => {
    dispatch(
      getSyncConnectorIntegrationsData(lowerCase(tableSource), id, undefined, {
        filters: { ...filteredData },
      })
    );
  };

  const submitFilters = (values) => {
    const keys = Object.keys(values);
    let filteredData = {};
    keys.forEach((key) => {
      if (values[key]) {
        switch (key) {
          case 'create_date':
            filteredData = {
              ...filteredData,
              [key]: moment(values[key]).format('YYYY-MM-DD'),
            };
            break;
          case 'status':
            if (values[key] !== 'all') {
              filteredData = {
                ...filteredData,
                [key]: values[key] === 'success' ? true : false,
              };
            }
            break;
          case 'item_id':
            filteredData = {
              ...filteredData,
              [key]: parseInt(values[key]),
            };
            break;
          case 'searchVal':
            if (tableSource === MANUFACTURER) {
              filteredData = {
                ...filteredData,
                description: values[key],
              };
            } else if (tableSource === DISTRIBUTOR) {
              filteredData = {
                ...filteredData,
                name: values[key],
              };
            }
            break;
          case 'manufaturerMappedState':
            if (values[key] !== MANUFACTURER_MAPPED_STATE.ALL) {
              filteredData = {
                ...filteredData,
                maping_status:
                  values[key] === MANUFACTURER_MAPPED_STATE.MAPPED
                    ? true
                    : false,
              };
            }
            break;
          default:
            filteredData = {
              ...filteredData,
              [key]: values[key],
            };
            break;
        }
      }
    });
    updateData(filteredData);
  };

  const logsRefreshBtn = () => {
    return (
      <Col>
        <Button
          type={'secondary'}
          icon={<SyncOutlined />}
          onClick={() => {
            dispatch(
              getSyncConnectorIntegrationsData(
                lowerCase(tableSource),
                id,
                undefined,
                {
                  filters: { ...logsData.filters },
                }
              )
            );
          }}
          style={{ ...marginSpace }}
        >
          Refresh
        </Button>
      </Col>
    );
  };

  return (
    <>
      <Row
        justify={'end'}
        style={{ backgroundColor: '#f4f4f4', padding: '8px' }}
      >
        <Col span={24}>
          <Form
            layout='vertical'
            className={'filter-form'}
            form={integrationFiltersForm}
            onFinish={submitFilters}
          >
            <Row>
              <Col>
                {tableSource !== LOGS && (
                  <Form.Item name={'searchVal'}>
                    <Input
                      style={{ width: '190px' }}
                      placeholder={`Search Blackleaf ${tableSource}`}
                    />
                  </Form.Item>
                )}
              </Col>
              <Col>
                {tableSource === MANUFACTURER && manufacturerAddedFilters()}
              </Col>
              <Col>{tableSource === LOGS && logsFilters()}</Col>
              <Col>
                <Form.Item labelCol={{ span: 8 }}>
                  <Button
                    type={'primary'}
                    htmlType='submit'
                    style={{ ...marginSpace }}
                  >
                    Apply
                  </Button>
                </Form.Item>
              </Col>
              {tableSource === LOGS && logsRefreshBtn()}
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};
