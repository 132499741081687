export const PRIMARY_COLOR = "#eeaa1e";
export const LAYOUT_BODY_BACKGROUND = "white";
export const DEFAULT_FONT_WEIGHT = 500;
export const DEFAULT_BORDER_RADIUS = "2px";

export const SYSTEM_OPTIONS = "System Options";
export const GENERAL = "General";
export const DISTRIBUTOR = "Distributor";
export const MANUFACTURER = "Manufacturer";
export const CATEGORY = "Category";
export const LABOR_CATEGORY = "Labor Category";
export const ITEM_TYPE = "Item Type";
export const LOGS = "Logs";
export const INTEGRATION_LIST = "integration_list";
export const INTGERATION_DROPDOWN_LIST = "integration_dropdown_list";

export const BATCH = "batch";
export const CATALOG = "catalog";
export const ARCHIVE = "ARCHIVE";

export const SYNC_CONNECTOR_GENERAL_TAB_FIELDS = [
  {
    value: "String",
    display: "String",
  },
  {
    value: "NULL",
    display: "Null",
  },
  {
    value: "BLANK",
    display: "Blank",
  },
  {
    value: "FIELD",
    display: "Field",
  },
  {
    value: "Decimal(8, 3)",
    display: "Decimal(8, 3)",
  },
  {
    value: "Target",
    display: "Target",
  },
];

export const BAD_CPN_CALLBACK_FUNC_NAMES = {
  UPDATE_CATALOGS_DATA: "updateCatalogsData",
  GET_CATALOGS_DATA: "getCatalogsData",
  GET_CATALOG_SYNC_CONNECTOR_DATA: "getCatalogConnectorData",
};

export const INTEGRATION_SYSTEM_OPTIONS_INACTIVE_ITEMS = (
  syncConnectorTypeId
) => {
  let integrationType = syncConnectorTypeId === 6 ? "Quickbooks" : "Fieldhub";
  let arr = [
    {
      value: "REMAIN_ACTIVE",
      label: `Remain Active on ${integrationType}`,
    },
    {
      value: "REMAIN_INACTIVE",
      label: `Inactivate on ${integrationType}`,
    },
  ];

  if (syncConnectorTypeId === 6) {
    arr.push({
      value: "INACTIVATE_WHEN_ZERO_INVENTORY",
      label: "Inactivate if the Inventory is zero",
    });
  }

  return arr;
};

export const MANUFACTURER_MAPPED_STATE = {
  MAPPED: "Mapped",
  UNMAPPED: "Unmapped",
  ALL: "All",
};
